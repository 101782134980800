import { h } from "../../../_snowpack/pkg/preact.js";
import { seed } from "../../utils/random/index.js";
export const LowFrameRateWarning = ({
  setIsLowFrameRate
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentPixelation = urlParams.get("pixelation") ?? "1";
  const isAlreadyPixelated = currentPixelation !== "1";
  return h("div", {
    className: "uiButton modal centerModal"
  }, h("p", null, "it looks like this page is ", isAlreadyPixelated ? "still " : null, "running ", isAlreadyPixelated ? "a bit " : null, " slowly. for a smoother framerate, try refreshing the site in a smaller window, or run a slightly", " ", isAlreadyPixelated ? "more" : null, " pixelated version of the the animation."), h("div", {
    className: "buttonContainer"
  }, h("button", {
    className: "uiButton"
  }, h("a", {
    href: `/?pixelation=${parseFloat(currentPixelation) + 0.25}&world=${seed}`
  }, "pixelate", isAlreadyPixelated ? " again" : null)), h("button", {
    className: "uiButton",
    onClick: () => setIsLowFrameRate(false)
  }, "dismiss")));
};