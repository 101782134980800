import { h } from "../../../_snowpack/pkg/preact.js";
import { useState } from "../../../_snowpack/pkg/preact/hooks.js";
import { seed } from "../../utils/random/index.js";
export const ShareModal = ({
  setShowShareModal
}) => {
  const [copyButtonText, setCopyButtonText] = useState("copy");
  const url = `${window.location.host}/?world=${seed}`;
  const handleCopy = async () => {
    await navigator.clipboard.writeText(url);
    setCopyButtonText("copied");
    setTimeout(() => setCopyButtonText("copy"), 2000);
  };
  return h("div", {
    className: "uiButton modal centerModal"
  }, h("p", null, "this world can be revisited with the below link"), h("p", {
    className: "shareUrl",
    onClick: handleCopy
  }, url), h("div", {
    className: "buttonContainer"
  }, h("button", {
    className: "uiButton",
    onClick: handleCopy
  }, copyButtonText), h("button", {
    className: "uiButton",
    onClick: () => setShowShareModal(false)
  }, "dismiss")));
};