import { h, Fragment } from "../_snowpack/pkg/preact.js";
import { useState } from "../_snowpack/pkg/preact/hooks.js";
import { ShowInfoButton, RefreshButton, ShareButton, LowFrameRateWarning, ShareModal } from "./components/UI/index.js";
import { updateSeed } from "./utils/random/index.js";
import { Scene } from "./Scene.js";
import "./styles.css";
const App = () => {
  const [uiIsShown] = useState(!window.location.search.includes("no-ui"));
  const [showShareModal, setShowShareModal] = useState(false);
  const [isLowFrameRate, setIsLowFrameRate] = useState(false);
  const [refreshState, forceRefresh] = useState({});
  const handleRefresh = () => {
    forceRefresh({});
    updateSeed();
  };
  return h("main", null, h(Scene, {
    refreshState: refreshState,
    setIsLowFrameRate: setIsLowFrameRate
  }), uiIsShown && h(Fragment, null, h("div", {
    className: "uiWrapper"
  }, h("div", null, h(ShareButton, {
    onClick: () => setShowShareModal(prev => !prev)
  }), h(RefreshButton, {
    onClick: handleRefresh
  })), h(ShowInfoButton, null)), h("div", {
    className: "modalWrapper"
  }, showShareModal && h(ShareModal, {
    setShowShareModal: setShowShareModal
  }), isLowFrameRate && h(LowFrameRateWarning, {
    setIsLowFrameRate: setIsLowFrameRate
  }))));
};
export default App;