import { h } from "../../../_snowpack/pkg/preact.js";
import { useEffect, useRef, useState } from "../../../_snowpack/pkg/preact/hooks.js";
export const ShowInfoButton = () => {
  const buttonRef = useRef(null);
  const modalRef = useRef(null);
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  useEffect(() => {
    const handleEscape = e => {
      if (e.key === "Escape") setInfoIsVisible(false);
    };
    const handleClickOutside = event => {
      if (buttonRef.current && modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setInfoIsVisible(false);
      }
    };
    if (infoIsVisible) {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [modalRef, infoIsVisible]);
  return h("div", null, infoIsVisible && h("div", {
    ref: modalRef,
    className: "uiButton modal infoModal",
    "aria-describedBy": "infoButton",
    hidden: !infoIsVisible
  }, h("p", null, "a scifi-inspired study of signed distanced functions and noise fields in webgl, by", " ", h("a", {
    href: "https://moojok.space",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "moojok"), "."), h("p", null, h("a", {
    href: "https://en.wikipedia.org/wiki/Signed_distance_function",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "signed distance functions"), " ", "are fun. With them, you can compute the distance to an object in a metric space, provided you have a function to describe that object's volume."), h("p", null, "when used alongside", " ", h("a", {
    href: "https://en.wikipedia.org/wiki/Volume_ray_casting",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "ray marching"), " ", "techniques, you can render views of these 3d objects as seen through a 2d plane. this project is an experiment in combining this method with various noise fields, to manipulate and distort these views.")), h("button", {
    ref: buttonRef,
    id: "infoButton",
    className: "uiButton infoButton",
    "aria-label": "info",
    "aria-expanded": infoIsVisible,
    tabIndex: 0,
    onClick: () => setInfoIsVisible(previnfoIsVisible => !previnfoIsVisible)
  }, h("span", {
    role: "tooltip"
  }, infoIsVisible ? "x" : "i")));
};