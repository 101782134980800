import { h } from "../../../_snowpack/pkg/preact.js";
import { useRef, useEffect } from "../../../_snowpack/pkg/preact/hooks.js";
import { useAnimationFrame } from "../../hooks/useAnimationFrame.js";
import { compileShader, createAttribute, getUniformLocation, setUniform } from "./helpers.js";

/** Component props for the WebGLRenderer */

/**
 * A canvas component for running fragment shaders. Handles rendering and cleanup.
 */
export const WebGLRenderer = ({
  sketch: setupSketch,
  settings = {}
}) => {
  const canvasElement = useRef(null);
  const drawProps = useRef({});
  const drawFunction = useRef();
  const uniformsRef = useRef({});
  const {
    dimensions = [window.innerWidth, window.innerHeight],
    isAnimated = true,
    animationSettings = {}
  } = settings;

  // TODO: width and height are updated on rerenders, and the canvas has updated dimensions
  // but only the size that was visible on pageload will be 'painted' 🤔
  const [width, height] = dimensions;
  const {
    fps: throttledFps,
    delay,
    endAfter
  } = animationSettings;

  // Here we pass in our animation settings and created `onFrame` props to a `requestAnimationFrame` hook.
  const {
    startAnimation,
    stopAnimation
  } = useAnimationFrame(animationProps => {
    drawFunction.current?.({
      ...drawProps.current,
      uniforms: uniformsRef.current,
      frameCount: animationProps.frameCount,
      elapsedTime: animationProps.elapsedTime,
      fps: animationProps.fps,
      startAnimation,
      stopAnimation,
      isPlaying: animationProps.isPlaying,
      mouseHasEntered: animationProps.mouseHasEntered,
      mousePosition: animationProps.mousePosition,
      mouseIsDown: animationProps.mouseIsDown,
      mouseIsIdle: animationProps.mouseIsIdle
    });
  }, {
    willPlay: isAnimated,
    fps: throttledFps,
    delay,
    endAfter,
    domElementRef: canvasElement
  });

  // Default vertex shader used to pass our vUv co-ordinate varying to the fragment shader
  const defaultVert = "\n    precision highp float;\n    attribute vec2 position;\n    attribute vec2 uv;\n    varying vec2 vUv;\n    void main() {\n      vUv = uv;\n      gl_Position = vec4(position, 0.0, 1.0);\n    }\n  ";

  // Default Fragment shader — a white canvas
  const defaultFrag = "\n    void main() {\n      gl_FragColor = vec4(1.0);\n    }\n  ";
  useEffect(() => {
    // Create WebGL context and program
    const canvas = canvasElement.current;
    if (!canvas) {
      return;
    }
    const gl = canvas.getContext("webgl");
    const program = gl.createProgram();

    // initiallise our drawProps object
    const initialSketchProps = {
      gl,
      program,
      uniforms: uniformsRef.current,
      width,
      height,
      aspect: width / height,
      mouseHasEntered: false,
      mousePosition: [0, 0]
    };

    // Which we then pass to the sketch function
    const sketchObject = setupSketch(initialSketchProps);
    const uniforms = sketchObject.uniforms;
    const vert = sketchObject.vert ?? defaultVert;
    const frag = sketchObject.frag ?? defaultFrag;
    const onFrame = sketchObject.onFrame;

    // compile and initialise our shaders
    const vertexShader = compileShader(vert, gl.VERTEX_SHADER, gl);
    const fragmentShader = compileShader(frag, gl.FRAGMENT_SHADER, gl);
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.useProgram(program);

    // Create the uniforms we defined and store their details for later
    // so we can keep them up to date and handle cleanup
    const createdUniforms = Object.entries(uniforms).reduce((acc, [key, {
      value,
      type
    }]) => {
      const handle = getUniformLocation(key, program, gl);
      setUniform(handle, value, type, gl);
      return [...acc, {
        key,
        handle,
        type
      }];
    }, []);

    // Create out position data and give it to the shaders as an attribute
    /* prettier-ignore */
    const vertexData = new Float32Array([-1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0, -1.0]);
    const positionAttr = createAttribute("position", vertexData, program, gl);

    // Create out uv data and give it to the shaders as an attribute
    /* prettier-ignore */
    const uvData = new Float32Array([0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0]);
    const uvAttr = createAttribute("uv", uvData, program, gl);

    // update our ref that stores the drawprops, which is used above in the animation hook
    drawProps.current = initialSketchProps;
    uniformsRef.current = uniforms;

    // update our ref that stores the drawFucntion, which will be called in each frame of the animation
    drawFunction.current = currentDrawProps => {
      onFrame?.(currentDrawProps);

      // update our uniforms
      createdUniforms.forEach(({
        key,
        handle,
        type
      }) => {
        const newValue = uniformsRef.current[key].value;
        setUniform(handle, newValue, type, gl);
      });
      gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    };

    // Fix for intermittent lack of rendering on Safari & iOS
    /* prettier-ignore */
    setTimeout(() => {
      canvas.width += 1;
      canvas.width -= 1;
    }, 1);
    return () => {
      // cleanup our context
      gl.deleteBuffer(positionAttr.buffer);
      gl.disableVertexAttribArray(positionAttr.handle);
      gl.deleteBuffer(uvAttr.buffer);
      gl.disableVertexAttribArray(uvAttr.handle);
    };
  }, [setupSketch, settings, width, height, defaultVert, defaultFrag]);
  useEffect(
  // on component unmount, fully lose the gl context
  () => () => {
    const gl = drawProps.current.gl;
    gl.canvas.width = 1;
    gl.canvas.height = 1;
    const loseContext = gl.getExtension("WEBGL_lose_context");
    loseContext?.loseContext();
  }, []);
  return h("canvas", {
    ref: canvasElement,
    width: width,
    height: height
  });
};

// <- TYPES ->

/**
 * Settings for the sketch
 */
/**
 * Props to be recieved by the sketch.
 */
/**
 * The setup function to be passed into the React component, with access to `ShaderDrawProps`.
 *
 * The contents of this function should contain all sketch state, and can return shaders, uniforms,
 * and an onFrame callback function
 */
/**
 * The draw function returned by `ShaderSetupFn`, with access to `ShaderDrawProps`.
 *
 * If the sketch is animated, this function will be called every frame.
 */