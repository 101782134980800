import { useEffect, useRef, useCallback } from "../../_snowpack/pkg/preact/hooks.js";
import { getMean } from "../utils/math/index.js";
/**
 * A custom hook to use `requestAnimationFrame` in a React component, with interactivity
 *
 * @param onFrame - A callback to be run on every frame of the animation
 * @param options - An optional configuration object for the animation
 * @returns An object containing refs to the animations `OnFrameProps`, as well as a functions to stop and start the animation
 */
export const useAnimationFrame = (onFrame, options = {}) => {
  const {
    onStart,
    onEnd,
    delay,
    endAfter,
    fps: throttledFps,
    willPlay = true,
    domElementRef
  } = options;
  const requestRef = useRef(0);
  const startTimeRef = useRef(performance.now());
  const prevFrameTimeRef = useRef(performance.now());
  const isPlaying = useRef(false);
  const elapsedTime = useRef(0);
  const frameCount = useRef(1);
  const fpsArray = useRef(new Array(20).fill(throttledFps ?? 60));
  const averageFps = useRef(throttledFps ?? 60);
  const mousePosition = useRef([0, 0]);
  const mouseHasEntered = useRef(false);
  const mouseIsIdle = useRef(true);
  const mouseIsDown = useRef(false);
  const animate = useCallback(timestamp => {
    elapsedTime.current = Math.round(timestamp - startTimeRef.current);
    const deltaTime = (timestamp - prevFrameTimeRef.current) / 1000;
    const currentFps = Math.round(1 / deltaTime);
    const runFrame = () => {
      onFrame?.({
        elapsedTime: elapsedTime.current,
        frameCount: frameCount.current,
        fps: averageFps.current,
        isPlaying: isPlaying.current,
        mouseHasEntered: mouseHasEntered.current,
        mousePosition: mousePosition.current,
        mouseIsDown: mouseIsDown.current,
        mouseIsIdle: mouseIsIdle.current
      });
      frameCount.current += 1;
      fpsArray.current.shift();
      fpsArray.current = [...fpsArray.current, currentFps];
      averageFps.current = getMean(fpsArray.current);
      prevFrameTimeRef.current = timestamp;
    };
    if (throttledFps) {
      if (deltaTime >= 1 / throttledFps) runFrame();
    } else {
      runFrame();
    }
    requestRef.current = requestAnimationFrame(animate);
  }, [onFrame, throttledFps]);
  const startAnimation = useCallback(() => {
    if (!isPlaying.current) {
      requestRef.current = requestAnimationFrame(animate);
      isPlaying.current = true;
    }
  }, [animate]);
  const stopAnimation = () => {
    if (isPlaying.current) {
      cancelAnimationFrame(requestRef.current);
      isPlaying.current = false;
    }
  };
  useEffect(() => {
    if (willPlay) {
      setTimeout(() => {
        startAnimation();
        onStart?.();
      }, delay ?? 0);
      if (endAfter) {
        setTimeout(() => {
          stopAnimation();
          onEnd?.();
        }, endAfter);
      }
    }
    return () => stopAnimation();
  }, [animate, onStart, onEnd, delay, endAfter, willPlay, startAnimation]);
  useEffect(() => {
    const element = domElementRef?.current;
    let idleTimeout;
    const handleIdleChange = () => {
      mouseIsIdle.current = false;
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => {
        mouseIsIdle.current = true;
      }, 3500);
    };
    const updateMousePosition = (x, y) => {
      const canvasBounds = element.getBoundingClientRect();
      const posX = x - canvasBounds.left;
      const posY = y - canvasBounds.top;
      mousePosition.current = [posX, posY];
      mouseHasEntered.current = true;
      handleIdleChange();
    };
    const handleMouseDown = e => {
      updateMousePosition(e.clientX, e.clientY);
      mouseIsDown.current = true;
    };
    const handleMouseMove = e => {
      updateMousePosition(e.clientX, e.clientY);
    };
    const handleTouchStart = e => {
      const touch = e.touches[0];
      updateMousePosition(touch.clientX, touch.clientY);
      mouseIsDown.current = true;
    };
    const handleTouchMove = e => {
      const touch = e.touches[0];
      updateMousePosition(touch.clientX, touch.clientY);
    };
    const handleMouseAndTouchUp = () => {
      mouseIsDown.current = false;
    };
    element?.addEventListener("mousedown", handleMouseDown);
    element?.addEventListener("mousemove", handleMouseMove);
    element?.addEventListener("mouseup", handleMouseAndTouchUp);
    element?.addEventListener("touchstart", handleTouchStart);
    element?.addEventListener("touchmove", handleTouchMove);
    element?.addEventListener("touchend", handleMouseAndTouchUp);
    return () => {
      element?.removeEventListener("mousedown", handleMouseDown);
      element?.removeEventListener("mousemove", handleMouseMove);
      element?.removeEventListener("mouseup", handleMouseAndTouchUp);
      element?.removeEventListener("touchstart", handleTouchStart);
      element?.removeEventListener("touchmove", handleTouchMove);
      element?.removeEventListener("touchend", handleMouseAndTouchUp);
    };
  }, [domElementRef]);
  return {
    elapsedTime,
    frameCount,
    fps: averageFps,
    stopAnimation,
    startAnimation,
    isPlaying,
    mouseHasEntered,
    mousePosition,
    mouseIsDown,
    mouseIsIdle
  };
};

/**
 * An optional configuration object for `useAnimationFrame`
 */

/**
 * Props for the callback that will be run on every frame of the animation
 */

/**
 * The returned object from `useAnimationFrame`
 */